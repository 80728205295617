import { useState } from "react";
import "../styles/home.css";
import Productos from "./productos";
import ProductosComprados from "./productosComprados";
import VentaProductosComprados from "./ventaProductosComprados";
import Insumos from "./Insumos";
import InsumosComprados from "./InsumosComprados";
import Ganancias from "./ganancias";
import "../styles/listaElementos.css";

const Home = () => {
    const [ventanaActual,setVentanaActual]=useState(null);
    const seleccionarVentana = (ventana)=>{
        switch(ventana){
            case "productos":
                    setVentanaActual(<Productos></Productos>);
                break;
            case "productosComprados":
                    setVentanaActual(<ProductosComprados></ProductosComprados>);
                break;
            case "ventaProductosComprados":
                setVentanaActual(<VentaProductosComprados></VentaProductosComprados>);
                break;
            case "insumos":
                setVentanaActual(<Insumos></Insumos>)
                break;
            case "insumosComprados":
                setVentanaActual(<InsumosComprados></InsumosComprados>);
                break;
            case "ganancias":
                setVentanaActual(<Ganancias></Ganancias>);
        }
    }
    return (
        <div id="home">
            <nav className="menuNavegacion">
                <ul>
                    <li><p>Gestion productos</p>
                        <ul>
                            <li><button onClick={()=>{seleccionarVentana("productos")}}>Productos</button></li>
                            <li><button onClick={()=>{seleccionarVentana("productosComprados")}}>Productos comprados</button></li>
                           
                        </ul>
                    </li>
                    <li><p>Gestion insumos</p>
                        <ul>
                            <li><button onClick={()=>{seleccionarVentana("insumos")}}>Insumos</button></li>
                            <li><button onClick={()=>{seleccionarVentana("insumosComprados")}}>Insumos comprados</button></li>
                        </ul>
                    </li>
                    <li><p>Ventas</p>
                        <ul>
                            <li><button onClick={()=>{seleccionarVentana("ganancias")}}>Ver ganancias</button></li>
                        </ul>
                    </li>
                </ul>
              
            </nav>
            <main>
                {ventanaActual}
            </main>
        </div>
    );
}
 
export default Home;