import { useEffect } from "react";
import "../styles/modalComponent.css"

const ModalInput = ({ confirm, cancel, inputs, title }) => {

    useEffect(
        ()=>{
        },
        []

    );

    const cofirmarModal = (event) => {
        event.preventDefault();
        confirm(inputs);
    }

    const inputChange = (input, valor) => {
        input.value = valor;
        inputs = { ...inputs, input };
    }


    return (
        <div className="modalComponent">
            
            <form onSubmit={cofirmarModal}>
            <h4>{title}</h4>
                {inputs.map(input =>
                    <div key={input.name}>
                        <label>{input.name}</label>
                        <input type={input.type} defaultValue={input.value} onChange={(event) => { inputChange(input, event.target.value) }} required></input>
                    </div>
                )}
                <div className="acciones">
                    <input type="submit" value={"Confirmar"}></input>
                    <button type={"button"} onClick={cancel}>Cancelar</button>
                </div>
                
            </form>
        </div>
    );
}

export default ModalInput;