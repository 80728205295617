import { useEffect, useState } from "react";
import ProductosCompradosDAO from "../dao/productosCompradosDAO";
import "../styles/productosComprados.css"
import BajaProductoCompradoDAO from "../dao/bajaProductoCompradoDAO";
import FormVenderProductoComprado from "../components/FormVenderProductoComprado";
import ModalMensaje from "../components/ModalMensaje";
import ModalInput from "../components/ModalInput";
import esDispositivoMovil from "../dao/dispositivo";

const ProductosComprados = () => {

    const [productosComprados, setproductosComprados] = useState([]);
    const [productosCompradosMostrados, setproductosCompradosMostrados] = useState([]);
    const [estadoModal, setEstadoModal] = useState("modalDesactivado");
    const [modalActivo, setModalActivo] = useState(null);
    const [filtroProductosComprados, setFiltroProductosComprados] = useState({
        idProductoComprado: '',
        idProducto: '',
        estado: 'Disponible',
    })


    useEffect(() => {
        obtenerProductosComprados();

    }, []);
    useEffect(() => {
        filtrarProductosComprados();

    }, [filtroProductosComprados]);



    const filtrarProductosComprados = () => {
        let productoCompradoObtenidos = productosComprados.filter(
            productoComprado =>
                (productoComprado.idProducto + "").startsWith(filtroProductosComprados.idProducto) &&
                (productoComprado.idProductoComprado + "").startsWith(filtroProductosComprados.idProductoComprado) &&
                (productoComprado.estado + "").startsWith(filtroProductosComprados.estado)
        );
        setproductosCompradosMostrados(productoCompradoObtenidos);
    };

    const obtenerProductosComprados = async () => {
        let requestProductosComprados = await new ProductosCompradosDAO().obtenerProductosComprados();
        if (requestProductosComprados.status) {
            setproductosComprados(requestProductosComprados.data);
            setproductosCompradosMostrados(requestProductosComprados.data);
        }

    }

    const descartarProductoComprado = async (productoComprado) => {

        if (productoComprado.estado == "Vendido") {
            notificarMensaje("No se puede descartar un producto ya vendido");
        } else {
            const confirm = async (inptus) => {
                let requestDescartarProductoComprado = await new BajaProductoCompradoDAO().registrarBajaProducto(productoComprado.idProductoComprado, inptus[0].value, inptus[1].value);
                if (requestDescartarProductoComprado.status) {
                    notificarMensaje("Producto descartado correctamente");
                    obtenerProductosComprados();
                } else {
                    notificarMensaje("Error al descartar producto");
                }

            }
            let inputs = [
                {
                    name: "Motivo",
                    value: "",
                    type: "text"
                },
                {
                    name: "Fecha",
                    value: "",
                    type: "date"
                }
            ]
            let title = `Descartar producto con el id ${productoComprado.idProductoComprado}`;
            activarFormularioModal(confirm, inputs, title);
        }
    }
    const ocultarFormulario = () => {
        setEstadoModal("modalDesactivado");
        setModalActivo(null);
    }

    const eliminarProductoComprado = async (idProductoComprado) => {
        let requestEliminarProductoComprado = await new ProductosCompradosDAO().eliminarProductoComprado(idProductoComprado);
        if (requestEliminarProductoComprado.status) {
            notificarMensaje("Producto eliminado correctamente");
            obtenerProductosComprados();
        } else if (requestEliminarProductoComprado.message = 1451) {
            notificarMensaje("No se puede eliminar un producto ya vendido");
        }

    }

    const mostrarVenderProductoComprado = async (productoComprado) => {
        if (productoComprado.estado == "Disponible") {
            setEstadoModal("modalActivado");
            setModalActivo(<FormVenderProductoComprado ocultarFormulario={ocultarFormulario} obtenerProductosComprados={obtenerProductosComprados} productoComprado={productoComprado} notificarMensaje={notificarMensaje}></FormVenderProductoComprado>);
        } else {
            notificarMensaje("Solo se pueden vender productos disponibles");
        }
    }

    const notificarMensaje = (mensaje) => {
        let modal = <ModalMensaje mensaje={mensaje} ocultarModal={ocultarModal}></ModalMensaje>
        setEstadoModal("modalActivado");
        setModalActivo(modal);
    }

    const activarFormularioModal = (confirmCallback, inputs, title) => {
        let modal = <ModalInput confirm={confirmCallback} cancel={ocultarModal} inputs={inputs} title={title}></ModalInput>
        setEstadoModal("modalActivado");
        setModalActivo(modal);

    }

    const ocultarModal = () => {
        setEstadoModal("modalDesactivado");
    }



    return (
        <div id="ProductosComprados">
            <div className="addContainer">
                <h2>Productos comprados</h2>
            </div>
            <div className="filtroContainer">
                <h3>Filtros: </h3>
                <div>
                    <p>Id producto comprado</p>
                    <input onChange={(e) => { setFiltroProductosComprados({ ...filtroProductosComprados, idProductoComprado: e.target.value }) }}></input>
                </div>
                <div>
                    <p>Id producto</p>
                    <input onChange={(e) => { setFiltroProductosComprados({ ...filtroProductosComprados, idProducto: e.target.value }) }}></input>
                </div>
                <div>
                    <p>Estado</p>
                    <select onChange={(e) => { setFiltroProductosComprados({ ...filtroProductosComprados, estado: e.target.value }) }}>
                        <option value="">Todos</option>
                        <option value="Disponible">Disponible</option>
                        <option value="Baja">Baja</option>
                        <option value="Vendido">Vendido</option>
                    </select>
                </div>
            </div>
            {esDispositivoMovil() ?
                <div className="contenedorProductosComprados">
                    <div className="listaElementos">
                        {productosCompradosMostrados.map(productoComprado =>
                            <div className="elemento" key={productoComprado.idProductoComprado}>
                                <div className="detallesPrincipales">
                                    <div className="informacion">
                                        <p>Id producto comprado: {productoComprado.idProductoComprado}</p>
                                        <p>Id producto: {productoComprado.idProducto}</p>
                                        <p>Nombre: {productoComprado.nombre}</p>
                                        <p>Precio compra: {productoComprado.precioCompra}</p>
                                        <p>Precio venta base: {productoComprado.precioVentaBase}</p>
                                    </div>
                                </div>
                                <div className="informacionSecundaria">
                                    <p>Estado: {productoComprado.estado}</p>
                                    <p>Color: <input type="color" defaultValue={productoComprado.color} disabled></input></p>
                                    <p>Fecha compra: {productoComprado.fechaCompra}</p>
                                    <p>Información compra: {productoComprado.informacionCompra}</p>
                                </div>
                                <div className="acciones">
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto comprado" onClick={() => { eliminarProductoComprado(productoComprado.idProductoComprado) }}></img>
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/roto.gif`} title="Descartar producto comprado" onClick={() => { descartarProductoComprado(productoComprado) }}></img>
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/sell.gif`} title="Vender producto comprado" onClick={() => { mostrarVenderProductoComprado(productoComprado) }}></img>
                                </div>
                            </div>

                        )}

                    </div>
                </div>
                :
                <div className="tableContainer">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Id producto comprado</th>
                                <th>Id producto</th>
                                <th>Nombre</th>
                                <th>Color</th>
                                <th>Precio compra</th>
                                <th>Precio venta base</th>
                                <th>Estado</th>
                                <th>Información compra</th>
                                <th>Fecha compra</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productosCompradosMostrados.map(productoComprado =>
                                <tr key={productoComprado.idProductoComprado}>
                                    <td>{productoComprado.idProductoComprado}</td>
                                    <td>{productoComprado.idProducto}</td>
                                    <td>{productoComprado.nombre}</td>
                                    <td><input type="color" defaultValue={productoComprado.color} disabled></input></td>
                                    <td>{productoComprado.precioCompra}</td>
                                    <td>{productoComprado.precioVentaBase}</td>
                                    <td>{productoComprado.estado}</td>
                                    <td>{productoComprado.informacionCompra}</td>
                                    <td>{productoComprado.fechaCompra}</td>
                                    <td>
                                        <div className="acciones">
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/delete.gif`} title="Eliminar producto comprado" onClick={() => { eliminarProductoComprado(productoComprado.idProductoComprado) }}></img>
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/roto.gif`} title="Descartar producto comprado" onClick={() => { descartarProductoComprado(productoComprado) }}></img>
                                            <img src={`${process.env.PUBLIC_URL}/assets/icons/sell.gif`} title="Vender producto comprado" onClick={() => { mostrarVenderProductoComprado(productoComprado) }}></img>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <div className={"modal " + estadoModal}>
                {modalActivo}
            </div>
        </div>);
}

export default ProductosComprados;

